<template>
  <div class="overflow-hidden bg-white py-24 sm:py-32 bg-gray-100">
    <div class="mx-auto max-w-7xl px-6 lg:px-24">
      <h1 class="text-2xl font-bold my-4">
        What to expect during the transition
      </h1>
      <h4 class="text-lg font-medium my-4">
        We’ll do all of the heavy lifting to upgrade your account, but there are
        a few important dates and to-dos for you during this transition.
      </h4>
      <nav aria-label="Progress" class="w-full flex justify-center mt-8">
        <ol role="list" class="overflow-hidden">
          <li
            v-for="(step, stepIdx) in steps"
            :key="step.id"
            :class="[stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative']"
          >
            <template v-if="step.status === 'complete'">
              <div
                v-if="stepIdx !== steps.length - 1"
                class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-primary-700"
                aria-hidden="true"
              />
              <!-- Changing all a tags to divs and removing href for now (:href="step.href")-->
              <div class="group relative flex items-start">
                <span class="flex h-9 items-center">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary-700"
                  >
                    <img class="w-12" src="@/assets/images/check.svg" />
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 max-w-xl flex-col">
                  <span class="text-lg font-bold">{{ step.name }}</span>
                  <span
                    v-html="step.description"
                    class="text-sm text-neutral-800"
                  ></span>
                </span>
              </div>
            </template>
            <template v-else-if="step.status === 'current'">
              <div
                v-if="stepIdx !== steps.length - 1"
                class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-primary-700"
                aria-hidden="true"
              />
              <!-- Changing all a tags to divs and removing href for now (:href="step.href")-->

              <div class="group relative flex items-start" aria-current="step">
                <span class="flex h-9 items-center" aria-hidden="true">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary-700 bg-white"
                  >
                    <span class="h-2.5 w-2.5 rounded-full bg-primary-700" />
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 max-w-xl flex-col">
                  <span class="text-lg font-bold text-primary-700">{{
                    step.name
                  }}</span>
                  <span
                    v-html="step.description"
                    class="text-sm text-neutral-800"
                  ></span>
                </span>
              </div>
            </template>
            <template v-else>
              <div
                v-if="stepIdx !== steps.length - 1"
                class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-primary-700"
                aria-hidden="true"
              />
              <!-- Changing all a tags to divs and removing href for now (:href="step.href")-->
              <div class="group relative flex items-start">
                <span class="flex h-9 items-center" aria-hidden="true">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary-700 bg-white"
                  >
                    <span class="h-2.5 w-2.5 rounded-full bg-transparent" />
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 max-w-xl flex-col">
                  <span class="text-lg font-bold text-primary-700">{{
                    step.name
                  }}</span>
                  <span
                    v-html="step.description"
                    class="text-sm text-neutral-800"
                  ></span>
                </span>
              </div>
            </template>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      steps: [
        {
          name: "04/29/2024",
          description:
            "<span>(<strong>Wealthcare</strong>) If you have an HSA, you will need to provide your online consent to transfer by this deadline. This step saves you money and adds convenience. <a style='color: blue;' href='#FAQs'>See more</a>.</span><br/><br/><span>(<strong>UMB</strong>) If you have an HSA and do not want it automatically combined with your new HSA, you have until this deadline to opt out. However, opting out is not recommended. <a style='color: blue;' href='#FAQs'>See why</a>.</span>",
          href: "#",
          status: this.checkDate("04/29/2024"),
          id: "0001"
        },
        {
          name: "04/30/2024",
          description:
            "<span>If you have HSA investments, you will need to liquidate your investments by this deadline and submit the Transfer Form. <a style='color: blue;' href='#FAQs'>Here’s how</a>. The form is found <a href='#FAQs' style='color: blue;'>here</a>.</span>",
          href: "#",
          status: this.checkDate("04/30/2024"),
          id: "0002"
        },
        {
          name: "04/30/2024",
          description:
            "<span>Last day to use your blue RMR payment card.</span>",
          href: "#",
          status: this.checkDate("04/30/2024"),
          id: "0003"
        },
        {
          name: "04/30/2024",
          description:
            "<span>Last day to submit claims for reimbursement to your previous account. Save your claim history from the old portal, because previous claim history will not be visible on the new portal.</span>",
          href: "#",
          status: this.checkDate("04/30/2024"),
          id: "0004"
        },
        {
          name: "05/01/2024",
          description:
            "<span>Your new payment card will be mailed. It will be a white card with the RMR logo. Keep your eye on your mailbox and activate it right away so it’s ready when you need it!</span>",
          href: "#",
          status: this.checkDate("05/01/2024"),
          id: "0005"
        },
        {
          name: "05/01/2024 - 05/15/2024",
          description:
            "<span>The Quiet Period begins. All benefit type funds will be unavailable while they are transferring to your new account.</span>",
          href: "#",
          status: this.checkDate("05/01/2024"),
          id: "0006"
        },
        {
          name: "05/15/2024",
          description:
            "<span>Your new account and card are live! Sign in to your new account at X. Don’t forget to download the new RMR app and begin using your new card. <a href='#FAQs' style='color: blue;'>RMR App</a></span>",
          href: "#",
          status: this.checkDate("05/15/2024"),
          id: "0007"
        },
        {
          name: "05/15/2024",
          description:
            "<span>HSA funds will be available for use in your new account. Although we are not certain on the timing of the individual transfer form process yet. So, please have patience while we test that with RMR.</span>",
          href: "#",
          status: this.checkDate("05/15/2024"),
          id: "0008"
        }
      ]
    };
  },
  methods: {
    checkDate(dateProp) {
      var newDate = new Date(dateProp.slice(0, 10));
      var today = new Date();
      var upcoming = new Date(newDate);
      upcoming.setDate(upcoming.getDate() - 6);
      if (today >= newDate) {
        return "complete";
      } else if (today >= upcoming) {
        return "current";
      } else if (newDate < today) {
        return "upcoming";
      }
    }
  }
};
</script>

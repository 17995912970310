<template>
  <div class="bg-white">
    <div
      class="mx-auto max-w-7xl divide-y divide-gray-900/10 px-6 py-24 sm:py-32 lg:px-24 lg:py-40"
    >
      <h2
        id="FAQs"
        class="text-2xl font-bold leading-10 tracking-tight text-gray-900"
      >
        Frequently asked questions
      </h2>
      <dl class="mt-10 space-y-8 divide-y divide-gray-900/10">
        <div
          v-for="faq in faqs"
          :key="faq.id"
          class="pt-8 lg:grid lg:grid-cols-12 lg:gap-8"
        >
          <dt
            class="text-xl font-bold text-center px-4 py-8 lg:py-16 leading-7 text-gray-100 lg:col-span-5 bg-primary-700 flex items-center justify-center"
          >
            <div>
              {{ faq.question }}
            </div>
          </dt>
          <dd class="mt-4 lg:col-span-7 lg:mt-0 flex items-center">
            <div class="text-base leading-7 text-neutral-800">
              <p v-html="faq.answer"></p>
            </div>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          id: 1,
          question: "Why is RMR making this change?",
          answer:
            "<p>This change will bring participants a modern platform and greater conveniences, like instant claims processing, real-time claims reimbursements, and cards that have 'tap to pay' and digital wallet capabilities. With this change, we will be able to serve you even faster.</p>"
        },
        {
          id: 2,
          question: "What will I need to do?",
          answer:
            "<p>We will take care of moving your account for you, but there are a few things you will need to do, like switch to a new payment card, mobile app and portal. If you have an HSA, you will also have to agree to an account transfer and liquidate any investments. We’ll keep you updated on deadlines and what to do.</p>"
        },
        {
          id: 3,
          question: "Is my health insurance also changing?",
          answer:
            "<p>No, this change is limited to your benefits spending accounts only: health savings and flexible spending accounts.</p>"
        },
        {
          id: 4,
          question: "When will this change happen?",
          answer:
            "<p>You will begin using the upgraded system and your new payment card on <strong>5/15/2024</strong>.</p>"
        },
        {
          id: 5,
          question:
            "Will I need to create a new username and password for my new account?",
          answer:
            "<p>Yes, you will need to setup account credentials online at XXXX. You can set up your new credentials and log in your new account beginning <strong>5/15/2024</strong>. You will need your payment card to log in for the first time, so make sure you have it handy. </p>"
        },
        {
          id: 6,
          question: "Is there a new mobile app?",
          answer:
            "<p>Yes, you will have a new mobile app: <strong>RMR Benefits</strong>. It will be available in both the <a style='color:blue;' target='_blank' rel='noreferrer' href='https://apps.apple.com/us/app/rmr-benefits/id6479498415'>Apple App Store</a> and the <a style='color:blue;' target='_blank' rel='noreferrer' href='https://play.google.com/store/apps/details?id=com.rockymountainreserve.rmr_app&hl=en_US'>Google Play Store</a>.</p>"
        },
        {
          id: 7,
          question:
            "When will my existing funds be transferred to my new account?",
          answer:
            "<p>If you have an FSA, HRA, or commuter account, your funds will automatically transfer to your new account from <strong>5/1/2024 - 5/15/2024</strong>. During this time, your funds will be temporarily unavailable while they are transferring.</p><br/><p>(<strong>Wealthcare</strong>) If you have an HSA, your funds will automatically transfer to your new account from <strong>[Date - Date]</strong>, as long as you submit your online consent by <strong>[Date]</strong>. During this time, your funds will be temporarily unavailable while they are transferring.</p><br/><p>(<strong>UMB</strong>) If you have an HSA, your funds will automatically transfer to your new account from <strong>[Date - Date]</strong> (as long as you did not opt out of account consolidation). During this time, your funds will be temporarily unavailable while they are transferring.</p>"
        },
        {
          id: 8,
          question:
            "What happens if I need to use my account while my funds are transferring?",
          answer:
            "<p>Your funds transfer window is very short, so in some cases, you may be able to hold your payments until the funds settle. Alternatively, you can use an alternative payment method and submit your expense for reimbursement after your funds are transferred.</p> "
        },
        {
          id: 9,
          question: "When will my existing RMR payment card be deactivated?",
          answer:
            "<p>Your existing blue payment card will be deactivated on <strong>4/30/2024</strong>.</p> "
        },
        {
          id: 10,
          question:
            "When will I receive my payment card, and when can I start using it?",
          answer:
            "<p>Your new card should arrive sometime between <strong>5/1/2024 - 5/15/2024</strong>. Activate it right away so it is ready when you need it. You can begin using it for eligible transactions beginning <strong>5/15/2024</strong>. If you have multiple accounts, they will all be accessible from the same card. If you’ve moved recently, make sure your mailing address is up to date in the RMR system.</p>"
        },
        {
          id: 11,
          question: "What actions do I need to take for my HSA? ",
          answer:
            "<p>(<strong>Wealthcare</strong>) Yes, you will need to provide your consent to have your existing HSA funds transferred to your new account. The process is simple - we’ll send you an email prior to the transfer of your account that asks you to click a ‘consent’ button.If you have investments, you’ll need to liquidate your investments and make sure you turn off any automated investment feature (if any prior to your account transferring on <strong>5/1/2024</strong>.</p><br/><p>(<strong>UMB</strong>) Your funds will automatically be moved to your new account. If you have investments, you’ll need to liquidate your investments and make sure you turn off any automated investment feature (if any prior to your account transferring on <strong>5/1/2024</strong>.</p>"
        },
        {
          id: 12,
          question:
            "(Wealthcare) What if I do NOT consent to transfer my HSA funds to my new account? ",
          answer:
            "<p>If you do not consent to transfer, your funds will stay in your current account, and you will have two HSAs. Your account will no longer be employer supported. This means you will be charged a [$XX] monthly administration fee for your former account, you will no longer be able to make payroll contributions.</p>"
        }
      ]
    };
  }
};
</script>

<template>
  <div class="bg-primary-700 py-12">
    <view-header
      title="Your Benefits Accounts Are Getting an Upgrade"
      image="greenTrees2.jpg"
    >
    </view-header>
  </div>
</template>

<script>
import ViewHeader from "../../../../components/layout/ViewHeaderMicroSite.vue";

export default {
  components: {
    ViewHeader
  }
};
</script>
